<template>
    <div id="Vehicle_Profile" class="container-fluid p-0 m-0" style="min-height: 100vh; border-left: 4px solid white">

        <div class="row" v-if="loading">
            <div class="col-12">
                <li-spinner size="sm"/>
            </div>
        </div>

        <div class="row" v-if="!loading">
            <div class="col-12 pt-3">
                <button @click.stop="$router.back()"
                    class="btn btn-outline-primary btn-pill">
                    <img :src="arrow_left_icon" alt="" />
                    Regresar
                </button>
            </div>

            <div class="col-12 p-0 m-0 mt-3 pl-4">
                <p style="font-size: 28px;">
                    Información del Vehiculo
                </p>
            </div>

            <div class="col-12 p-0 m-0 mt-3">
                <div class="row p-0 m-0">

                    <div class="col-12 col-lg-6 order-2 order-md-2 order-lg-1">
                        <div class="row">

                            <div class="col-12">
                                <p style="margin: 0px; font-size: 20px;">
                                    Marca:
                                </p>
                                <span class="pl-3">
                                    {{ unit.brand_name }}
                                </span>
                                <hr style="border: 1px solid #008c9c;" class="m-0">
                            </div>

                            <div class="col-12 col-md-6 pt-2">
                                <p style="margin: 0px; font-size: 20px;">
                                    Modelo:
                                </p>
                                <span class="pl-3">
                                    {{ unit.model_name }} 
                                </span>
                                <hr style="border: 1px solid #008c9c;" class="m-0">
                            </div>

                            <div class="col-12 col-md-6 pt-2">
                                <p style="margin: 0px; font-size: 20px;">
                                    Tipo de vehiculo:
                                </p>
                                <span class="pl-3">
                                    {{ unit.vehicle_type_name }} 
                                </span>
                                <hr style="border: 1px solid #008c9c;" class="m-0">
                            </div>

                            <div class="col-12 col-md-6 pt-2">
                                <p style="margin: 0px; font-size: 20px;">
                                    Año:
                                </p>
                                <span class="pl-3">
                                    {{ unit.year }} 
                                </span>
                                <hr style="border: 1px solid #008c9c;" class="m-0">
                            </div>

                            <div class="col-12 col-md-6 pt-2">
                                <p style="margin: 0px; font-size: 20px;">
                                    No. de Serie:
                                </p>
                                <span class="pl-3">
                                    {{ unit.serial_number }} 
                                </span>
                                <hr style="border: 1px solid #008c9c;" class="m-0">
                            </div>

                            <div class="col-12 col-md-6 mt-4">
                                <button @click.stop="downloadReport"
                                    class="btn btn-pill btn-outline-primary"
                                    type="button">
                                    Descargar reporte
                                </button>
                            </div>

                        </div>
                    </div>

                    <div class="col-12 col-lg-6 d-flex justify-content-center justify-content-md-center justify-content-lg-start p-0 m-0 py-sm-3 py-lg-0 order-1 order-md-1 order-lg-2">
                        <div style="max-width: 260px;">
                            <img :src="unit.picture ?? placeholder_image"  alt=""
                            class="img-fluid" style="border-radius: 50%; height: 260px; object-fit: scale-down;"/>
                        </div>
                    </div>

                </div>  
            </div>

            <div class="col-12">
                <div class="row">

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Color:
                        </p>
                        <span class="pl-3 d-flex align-items-end justify-content-between">
                            {{ unit.color_name }} 
                            <div class="mr-4"
                                style="width: 35px; height: 35px; border: 1px solid white; border-radius: 50%"
                                :style="`background-color: #${unit.hex}`"></div>
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-6 pt-4">
                        <p style="margin: 0px; font-size: 20px;">
                            Placas:
                        </p>
                        <span class="pl-3">
                            {{ unit.plate }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Costo de Renta:
                        </p>
                        <span class="pl-3">
                             ${{ formatAmount(unit.rental_rate, 2) }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>

                    <div class="col-12 col-md-6 pt-3">
                        <p style="margin: 0px; font-size: 20px;">
                            Costo de acquisión:
                        </p>
                        <span class="pl-3">
                             ${{ formatAmount(unit.acquisition_cost, 2) }} 
                        </span>
                        <hr style="border: 1px solid #008c9c;" class="m-0">
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="plates_data.length > 0">
                <div class="row">
                    <div class="col-12">
                        <p style="font-size: 24px;">
                            Historial de Placas:
                        </p>
                    </div>

                    <div class="col-12">
                        <li-table :data="plates_data"
                            :allow_headers="plates_headers"
                            pagination_off
                            full>
                            <template v-slot:creation="data">
                                {{ new Date(data.item.creation).toLocaleDateString() }}
                            </template>
                        </li-table>
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="verifications_data.length > 0" >
                <div class="row">
                    <div class="col-12">
                        <p style="font-size: 24px;">
                            Relación de verificaciones:
                        </p>
                    </div>

                    <div class="col-12">
                        <li-table :data="verifications_data"
                            :allow_headers="verifications_headers"
                            pagination_off
                            full>
                            <template v-slot:period="data">
                                {{ data.item.period == 'first' ? 'Primer' : data.item.period == 'second' ? 'Segundo' : ''}}
                            </template>
                            <template v-slot:creation="data">
                                {{ new Date(data.item.creation).toLocaleDateString()}}
                            </template>
                        </li-table>
                    </div>
                </div>
            </div>

            <div class="col-12 pt-3 " v-if="rentals_data.length > 0">
                <div class="row">
                    <div class="col-12">
                        <p style="font-size: 24px;">
                            Relación de rentas:
                        </p>
                    </div>

                    <div class="col-12">
                        <li-table :data="rentals_data"
                            :allow_headers="rentals_headers"
                            pagination_off
                            full>

                            <template v-slot:rent_discount="data">
                                  $ {{ formatAmount(data.item.rent_discount, 2)  }} 
                            </template>
                            <template v-slot:start_date="data">
                                <span>
                                        {{ new Date(data.item.start_date).toLocaleString() }}
                                </span>
                            </template>
                            <template v-slot:end_date="data">
                                <span>
                                        {{ data.item.end_date ? new Date(data.item.end_date).toLocaleString() : 'Actualidad' }}
                                </span>
                            </template>
                        </li-table>
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="incidents_data.length > 0">
                <div class="row">
                    <div class="col-12">
                        <p style="font-size: 24px;">
                            Relación de incidencias:
                        </p>
                    </div>

                    <div class="col-12">
                        <li-table :data="incidents_data"
                            :allow_headers="incidents_headers"
                            pagination_off
                            full>
                            <template v-slot:type="data">
                                {{ data.item.type == 'mishap' ? 'Siniesto' : 'Admininstrativo' }}
                            </template>
                            <template v-slot:damages_cost="data">
                                {{ data.item.damages_cost ? `$ ${formatAmount(data.item.damages_cost, 2)}` : 'No especificado'}}
                            </template>
                            <template v-slot:date_incident="data">
                                {{ data.item.date_incident ?  new Date(data.item.date_incident).toLocaleDateString() : 'No definida'}}
                            </template>
                            <template v-slot:incident_status="data">
                                {{ data.item.incident_status == 'created' ? 'Creada' : data.item.incident_status== 'assigned' ? 'Asignada' : data.item.incident_status == 'valued' ? 'Valorada' : data.item.incident_status == 'for_approval' ? 'Esperando aprovación' : data.item.incident_status == 'final_revision' ? 'Revision final' : 'Terminada' }}
                            </template>
                            <template v-slot:actions="data">
                                <button @click.stop="viewIncident(data.item.id_incident)"
                                    class="btn btn-outline-primary btn-pill"
                                    type="button">
                                    Detalles
                                </button>
                            </template>
                        </li-table>
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="insurances_data.length > 0">
                <div class="row">
                    <div class="col-12">
                        <p style="font-size: 24px;">
                            Relación de Seguros:
                        </p>
                    </div>

                    <div class="col-12">
                        <li-table :data="insurances_data"
                            :allow_headers="insurances_headers"
                            pagination_off
                            full>
                            <template v-slot:premium_value="data">
                                 $ {{ formatAmount(data.item.premium_value, 2)  }}
                            </template>
                            <template v-slot:deductible="data">
                                  {{ data.item.deductible }} %
                            </template>
                            <template v-slot:insured_amount="data">
                                 $ {{ formatAmount(data.item.insured_amount, 2) }} 
                            </template>
                            <template v-slot:date_init="data">
                                {{ new Date(data.item.date_init).toLocaleDateString()}}
                            </template>
                            <template v-slot:date_end="data">
                                {{ new Date(data.item.date_end).toLocaleDateString()}}
                            </template>
                        </li-table>
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="maintenances_data.length > 0">
                <div class="row">
                    <div class="col-12">
                        <p style="font-size: 24px;">
                            Relación de mantenimientos:
                        </p>
                    </div>

                    <div class="col-12">
                        <li-table :data="maintenances_data"
                            :allow_headers="maintenances_headers"
                            pagination_off
                            full>
                            <template v-slot:creation="data">
                                {{ data.item.creation ? new Date(data.item.creation).toLocaleDateString() : 'Sin fecha' }}
                            </template>
                            <template v-slot:type="data">
                                {{ data.item.type == 'initial' ? 'Inicial' : data.item.type == 'routine' ? 'Rutina' : ''}}
                            </template>
                        </li-table>
                    </div>
                </div>
            </div>

            <div class="col-12" v-if="mileage_data.length > 0">
                <div class="row">
                    <div class="col-12">
                        <p style="font-size: 24px;">
                            Relación de kilometrajes:
                        </p>
                    </div>

                    <div class="col-12">
                        <li-table :data="mileage_data"
                            :allow_headers="mileage_headers"
                            pagination_off
                            full>
                            <template v-slot:creation="data">
                                {{ new Date(data.item.creation).toLocaleDateString()}}
                            </template>
                            <template v-slot:type="data">
                                {{ data.item.type == 'init' ? 'inicial' : data.item.type == 'update' ? 'Actualización' : '' }}
                            </template>
                        </li-table>
                    </div>
                </div>
            </div>

            
        </div>

        <li-modal :show_modal="details_modal" :max_width="900" class="px-3" v-if="details_modal"> 

                <div class="col-12 col-md-6 py-2">
                    <h4>
                        Detalles
                    </h4>
                </div>
                
                <div class="col-12 col-md-6  d-flex justify-content-center py-2">

                    <button @click.stop="switch_historial = !switch_historial"
                        class="btn btn-pill btn-primary ml-3"
                        style="height: 40px; padding: 0px 25px;">
                            Historial
                    </button>

                    <button @click.stop="details_modal = false; switch_historial = false"
                        class="btn btn-pill text-white ml-3"
                        style="height: 40px; padding: 0px 25px; background-color: #343A40;">
                            cerrar
                    </button>
                </div>

                <div class="col-12 p-0 m-0" v-if="!switch_historial">
                    <div class="row p-0 m-0">

                        <div class="col-6">
                            <p style="margin: 0px;;">
                                Conductor:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.driver_name }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Tipo de incidencia:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.type == 'mishap' ? 'Siniestro' : 'Administrativo' }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Placa:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.plate }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Modelo:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.model_name }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Responsable:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.user_responsible_name }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Estatus:
                            </p>
                            <span class="pl-3">
                                {{ incident_details.incident_status == 'created' ? 'Creada' : incident_details.incident_status == 'assigned' ? 'Asignada' : incident_details.incident_status == 'valued' ? 'Valorada' : incident_details.incident_status == 'for_approval' ? 'Esperando aprovación' : incident_details.incident_status == 'final_revision' ? 'Revision final' : 'Terminada'}}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Costo Estimado:
                            </p>
                            <span class="pl-3">
                                 {{ incident_details.estimated_price ? `$ ${formatAmount(incident_details.estimated_price, 2)}` : 'No valorado'}}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Entrega Estimada:
                            </p>
                            <span class="pl-3">
                                 {{ incident_details.estimated_date ? new Date(incident_details.estimated_date).toLocaleDateString() : 'No valorado'}}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Dias transcurridos:
                            </p>
                            <span class="pl-3">
                                 {{ incident_details.elapsed_days}}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                        <div class="col-6">
                            <p style="margin: 0px;">
                                Descripción:
                            </p>
                            <span class="pl-3">
                                 {{ incident_details.description ? incident_details.description : 'Sin descripción' }}
                            </span>
                            <hr style="border: 1px solid #008c9c;" class="m-0">
                        </div>

                    </div>
                </div>

                <div class="col-12" v-if="switch_historial">
                    <div class="border-primary py-2 my-2" v-for="(step, index) in incident_details.steps" :key="index"
                        style="border-radius: 10px;">
                        <div class="d-flex justify-content-between">
                            <span class="pl-3 manrope-bold" style="font-size: 1.2em;">
                                {{ step.incident_status == 'created' ? 'Creada' : step.incident_status == 'assigned' ? 'Asignado' : step.incident_status == 'valued' ? 'Valorado' : step.incident_status == 'for_approval' ? 'En revisión' : step.incident_status == 'final_revision' ? 'Revision final' : step.incident_status == 'declined' ? 'Rechazado' : 'Terminado'}}
                            </span>
                            <span class="pr-3">
                               Fecha: {{ new Date(step.creation).toLocaleDateString()}}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between" v-if="step.user_responsible_name">
                            <span class="pl-3">
                                Responsable: {{ step.user_responsible_name }}
                            </span>
                        </div>
                        <div class="d-flex justify-content-between" v-if="step.estimated_price || step.estimated_date">
                            <span class="pl-3" >
                              {{  step.estimated_price ? `Costo estimado: $ ${formatAmount(step.estimated_price, 2)}` : '' }} 
                            </span>
                            <span class="pr-3" >
                               Entrega estimada: {{ new Date(step.estimated_date).toLocaleDateString()}}
                            </span>
                        </div>
                    </div>
                </div>

        </li-modal>
    </div>
</template>


<script>
    //icons
    import arrow_left_icon from 'ASSETS/icons/arrow_left.png'
    import placeholder_image from 'ASSETS/images/placeholder-image.png'
    
    //helpers
    import { mapGetters } from 'vuex'

    export default {
        data(){
            return {
                arrow_left_icon,
                placeholder_image,
                details_modal: false,
                switch_historial: false,
                loading: false,
                vehicles_headers: [
                    {key: 'vehicle_name', label: 'Vehiculo'},
                    {key: 'license_plates', label: 'Placas'},
                    {key: 'color', label: 'Color'},
                    {key: 'serial_number', label: 'no. de serie'},
                    {key: 'from', label: 'Desde'},
                    {key: 'to', label: 'Hasta'}
                ],
                incidents_headers: [
                    {key: 'type', label: 'Tipo'},
                    {key: 'description', label: 'Descripción'},
                    {key: 'user_responsible_name', label: 'Resposable'},
                    {key: 'driver_name', label: 'Conductor'},
                    {key: 'incident_status', label: 'Estado'},
                    {key: 'date_incident', label: 'Fecha'},
                    {key: 'damages_cost', label: 'Costo final'},
                    {key: 'actions'}
                ],
                vehicles_data: [ 
                    { id: 1, vehicle_name: 'Beatle', license_plates: '3490JTYA',  color: 'Rojo', hex: 'FF0000', serial_number: '095533553222', from: '2023-01-20', to: '2023-04-20'},
                    { id: 2, vehicle_name: 'Beatle', license_plates: '3490JTYA', color: 'Verde', hex: '00FF00', serial_number: '095533553222', from: '2023-01-20', to: '2023-04-20'},
                ],
                incidents_data: [
                    {id: 1, incidence: 'Choque', type: 'Siniestro', vehicle: 'Beatle', date: '2023-03-04'}
                ],
                rentals_headers: [
                    { key: 'full_name', label: 'Conductor'},
                    { key: 'rent_discount', label: 'Descuento'},
                    { key: 'start_date', label: 'Desde'},
                    { key: 'end_date', label: 'Hasta'}
                ],
                rentals_data: [],
                insurances_headers: [
                    { key: 'policy_number', label: 'Número de Póliza'},
                    { key: 'premium_value', label: 'Prima del Seguro'},
                    { key: 'periodicity', label: 'Periodicidad'},
                    { key: 'date_init', label: 'Fecha de inicio'},
                    { key: 'date_end', label: 'Fecha de fin'}
                ],
                insurances_data: [],
                plates_data: [],
                plates_headers: [
                    { key: 'plate', label: 'Placa'},
                    { key: 'creation', label: 'Fecha de alta'}
                ],
                maintenances_data: [],
                maintenances_headers: [
                    {key: 'creation', label: 'Fecha'},
                    {key: 'current_mileage', label: 'Kilometraje'},
                    {key: 'type', label: 'Tipo'}
                ],
                mileage_data: [],
                mileage_headers: [
                    {key: 'creation', label: 'Fecha'},
                    {key: 'current_mileage', label: 'Kilometraje'},
                    {key: 'type', label: 'Tipo'}
                ],
                verifications_data: [],
                verifications_headers: [
                    {key: 'period', label: 'Periodo'},
                    {key: 'status', label: 'Estado'},
                    {key: 'creation', label: 'Fecha'}
                ],
                incident_details: null
            };
        },
        methods: {
            async viewIncident(id) {
                await this.$store.dispatch('incidents/viewIncident', { id_incident: id});
                this.incident_details = this.incident
                this.details_modal = true
            },
            async downloadReport() {
                window.open(this.report, '_blank')
            }
        },
        computed: {
            ...mapGetters({
                unit: 'unit_administration/getUnit',
                incident: 'incidents/getIncident',
                report: 'unit_administration/getReportUrl'
            })
        },
        async created(){
            try {

                this.loading = true
                //información del vehiculo
                let id_vehicle = this.$route.params.id;
                await this.$store.dispatch('unit_administration/viewVehicle', {id_vehicle: id_vehicle});
                await this.$store.dispatch('unit_administration/report', {id_vehicle: id_vehicle});
                this.rentals_data = this.unit.rentals.map( rent => {
                    return {
                        full_name: rent.full_name,
                        rent_discount: rent.rent_discount,
                        start_date: rent.start_date,
                        end_date: rent.end_date
                    }
                });

                this.insurances_data = this.unit.insurances ?? []
                this.plates_data = this.unit.license_plates ?? []
                this.maintenances_data = this.unit.mileage_maintenance ?? []
                this.mileage_data = this.unit.mileage ?? []
                this.verifications_data = this.unit.verifications ?? []
                this.incidents_data = this.unit.incidents.map( (incident) => {
                    return {
                        ...incident,
                        actions: '_'
                    }
                })

                console.log('unit', this.unit)

                this.loading = false
                
            }
            catch(error) {
                this.loading = false
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>